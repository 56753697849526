import { SidebarProvider } from "../ui/sidebar";
import { PrimeReactProvider } from "primereact/api";
import Tailwind from "primereact/passthrough/tailwind";
import { ThemeProvider } from "./theme-provider";

export function ApplicationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <SidebarProvider>
        <PrimeReactProvider value={{ unstyled: true, pt: Tailwind }}>
          {children}
        </PrimeReactProvider>
      </SidebarProvider>
    </ThemeProvider>
  );
}
