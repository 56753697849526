import { RegistrationForm } from "@/components/registration-form";
import { GlobalParamsInertia } from "@/types/global-params-inertia";
import { usePage } from "@inertiajs/react";

interface UsersRegistrationsNewInertiaProps extends GlobalParamsInertia {
  login_path: string;
  confirmation_path: string;
  register_path: string;
}

function UsersRegistrationsNew() {
  const { login_path, confirmation_path, register_path } = usePage()
    .props as unknown as UsersRegistrationsNewInertiaProps;

  return (
    <div className="flex h-screen w-full items-center justify-center px-4">
      <RegistrationForm
        loginPath={login_path}
        confirmationPath={confirmation_path}
        registerPath={register_path}
      />
    </div>
  );
}

export default UsersRegistrationsNew;
