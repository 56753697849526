import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Http } from "@/lib/http";
import { cn } from "@/lib/utils";
import { GlobalParamsInertia } from "@/types/global-params-inertia";
import { Machine } from "@/types/machine";
import { Telemetry } from "@/types/telemetry";
import { useForm, usePage } from "@inertiajs/react";
import { Check, ChevronLeft, ChevronsUpDown } from "lucide-react";
import { useEffect, useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import machineChannel from "../../channels/machine-channel";

interface MachineEdit extends GlobalParamsInertia {
  machine: Machine;
  telemetries: Telemetry[];
}

interface MachineForm {
  machine: {
    name: string;
    description: string;
    telemetry_id: string;
    mercado_pago_store_id: string;
    stock: number;
    pulse: number;
  };
}

export default function MachinesEdit() {
  const {
    machine,
    telemetries,
    auth: { user },
  } = usePage().props as unknown as MachineEdit;
  const currentUrl = usePage().url;
  const [machineState, setMachineState] = useState<Machine>(machine);
  const [open, setOpen] = useState(false);
  const { data, setData, put, processing } = useForm<MachineForm>({
    machine: {
      name: machine.name,
      description: machine.description,
      telemetry_id: machine.telemetry_id,
      mercado_pago_store_id: machine.mercado_pago_store_id,
      stock: machine.stock,
      pulse: machine.pulse,
    },
  });

  useEffect(() => {
    const subscription = machineChannel({
      telemetriaId: machine.telemetry.identifier,
      emailClient: machine.user.email,
      storeId: machine.mercado_pago_store_id,
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const navigateToMachine = () => {
    if (user.role === "admin") {
      Http.inertiaVisit({
        path: `/admin/clients/${machine.user.id}/machines/${machine.id}`,
      })
    }

    if (user.role === "client") {
      Http.inertiaVisit({
        path: `/machines/${machine.id}`,
      });
    }
  };

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setData({
      machine: {
        name: machineState.name,
        description: machineState.description,
        telemetry_id: machineState.telemetry.id,
        mercado_pago_store_id: machineState.mercado_pago_store_id,
        stock: machineState.stock,
        pulse: machineState.pulse,
      },
    });

    put(currentUrl.replace("/edit", ""), {
      onSuccess: () => {
        // navigateToMachine();
      },
    });
  };

  const SelectSearch = () => {
    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            className="justify-between"
            aria-expanded={open}
            disabled={user.role !== "admin"}
          >
            {machineState.telemetry.identifier
              ? telemetries.find(
                  (telemetry) =>
                    telemetry.identifier === machineState.telemetry.identifier
                )?.identifier
              : "Selecione uma telemetria..."}
            <ChevronsUpDown className="opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0">
          <Command>
            <CommandInput placeholder="Selecione uma telemetria..." />
            <CommandList>
              <CommandEmpty>Nenhuma telemetria encontrada</CommandEmpty>
              <CommandGroup>
                {telemetries.map((telemetry) => (
                  <CommandItem
                    key={telemetry.id}
                    value={telemetry.identifier}
                    onSelect={(currentValue) => {
                      setMachineState(
                        currentValue === machineState.telemetry.identifier
                          ? { ...machineState }
                          : { ...machineState, telemetry: telemetry }
                      );
                      setData({
                        machine: {
                          ...data.machine,
                          telemetry_id: telemetry.id,
                        },
                      });
                      setOpen(false);
                    }}
                  >
                    {telemetry.identifier}
                    <Check
                      className={cn(
                        "ml-auto",
                        machine.telemetry.identifier === telemetry.identifier
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <div>
      <Button variant="outline" className="mb-5" onClick={navigateToMachine}>
        <ChevronLeft />
        Voltar
      </Button>
      <div className="flex">
        <form
          className="md:w-[500px] w-full flex flex-col gap-4"
          onSubmit={submit}
        >
          <h1 className="text-xl font-medium text-primary">
            Máquina:{" "}
            <span className="font-light text-foreground">
              {machine.telemetry.identifier}
            </span>
          </h1>
          <div className="">
            <Label htmlFor="name" className="text-primary">
              Nome
            </Label>
            <Input
              id="name"
              type="text"
              required
              value={machineState.name}
              onChange={(e) => {
                setMachineState({ ...machineState, name: e.target.value });
                setData({ machine: { ...data.machine, name: e.target.value } });
              }}
            />
          </div>
          <div className="">
            <Label htmlFor="description" className="text-primary">
              Descrição
            </Label>
            <Input
              id="description"
              type="text"
              required
              value={machineState.description}
              onChange={(e) => {
                setMachineState({
                  ...machineState,
                  description: e.target.value,
                });
                setData({
                  machine: { ...data.machine, description: e.target.value },
                });
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <Label className="text-primary">Telemetria</Label>
            <SelectSearch />
          </div>
          <div className="">
            <Label htmlFor="mercado_pago_id" className="text-primary">
              Mercado Pago ID
            </Label>
            <Input
              id="mercado_pago_id"
              type="text"
              required
              value={machineState.mercado_pago_store_id}
              onChange={(e) => {
                setMachineState({
                  ...machineState,
                  mercado_pago_store_id: e.target.value,
                });
                setData({
                  machine: {
                    ...data.machine,
                    mercado_pago_store_id: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className="">
            <Label htmlFor="pulse" className="text-primary">
              Pulso
            </Label>
            <Input
              id="pulse"
              type="number"
              required
              value={machineState.pulse}
              onChange={(e) => {
                setMachineState({
                  ...machineState,
                  pulse: Number(e.target.value),
                });

                setData({
                  machine: { ...data.machine, pulse: Number(e.target.value) },
                });
              }}
            />
          </div>
          <div>
            <Label htmlFor="stock" className="text-primary">
              Estoque
            </Label>
            <Input
              id="stock"
              type="number"
              required
              value={machineState.stock}
              onChange={(e) => {
                setMachineState({
                  ...machineState,
                  stock: Number(e.target.value),
                });

                setData({
                  machine: { ...data.machine, stock: Number(e.target.value) },
                });
              }}
            />
          </div>
          <Button type="submit" className="w-full" disabled={processing}>
            {!processing && "Salvar"}
            {processing && "Entrando..."}
          </Button>
        </form>
      </div>
    </div>
  );
}
