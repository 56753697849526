import { Link } from "@inertiajs/react"
import logo from "../../assets/logomp.png"
import { Button } from "@/components/ui/button"

function HomeIndex() {
  return (
    <div className="flex flex-col justify-center h-screen items-center gap-6 bg-gradient-to-r from-[#0C0C2A] to-primary">
      <img src={logo} alt="" className="w-80 rounded-lg" />

      <Link href="/users/sign_in">
        <Button variant="secondary">
          Entrar
        </Button>
      </Link>
</div>
  )
}

export default HomeIndex