import { Link, useForm } from "@inertiajs/react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

type LoginFormProps = {
  loginPath: string;
  resetPasswordPath: string;
  registerPath: string;
};

export function LoginForm({ loginPath, resetPasswordPath, registerPath }: LoginFormProps) {
  const { data, setData, post, processing } = useForm({
    user: { email: "", password: "", remember: false },
  });

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    post(loginPath);
  }

  return (
    <Card className="mx-auto max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">Login</CardTitle>
        <CardDescription>
          Insira seu e-mail abaixo para fazer login em sua conta
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form className="grid gap-4" onSubmit={handleSubmit}>
          <div className="grid gap-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="m@example.com"
              required
              value={data.user.email}
              onChange={(e) =>
                setData({ user: { ...data.user, email: e.target.value } })
              }
            />
          </div>
          <div className="grid gap-2">
            <div className="flex items-center">
              <Label htmlFor="password">Senha</Label>
              <a
                href={resetPasswordPath}
                className="ml-auto inline-block text-sm underline"
              >
                Esqueceu sua senha?
              </a>
            </div>
            <Input
              id="password"
              type="password"
              required
              value={data.user.password}
              onChange={(e) =>
                setData({ user: { ...data.user, password: e.target.value } })
              }
            />
          </div>
          <Button type="submit" className="w-full" disabled={processing}>
            {!processing && "Entrar"}
            {processing && "Entrando..."}
          </Button>
        </form>
        <div className="mt-4 text-center text-sm">
          Ainda não tem uma conta?{" "}
          <Link href={registerPath} className="underline">
            Registre-se
          </Link>
        </div>
      </CardContent>
    </Card>
  );
}
