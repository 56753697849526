import { createConsumer } from "@rails/actioncable"

export default function createConsumerCustom(telemetryId: string) {
  return createConsumer(getWebSocketUrl() + `?telemetria_id=${telemetryId}`)
}

function getWebSocketUrl() {
  const metaTag = document.querySelector('meta[name="action-cable-url"]')
  //@ts-ignore
  // return metaTag ? metaTag.content : "ws://localhost:3000/cable";
  return process.env.NODE_ENV === 'development' ? "ws://localhost:3000/cable" : "wss://bluepaypag.com/cable"
}
