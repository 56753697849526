import { LucideIcon } from "lucide-react";
import { Input } from "./ui/input";

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  Icon: LucideIcon;
}

export function CustomInput({ Icon, ...props }: CustomInputProps) {
  return (
    <div className="relative">
      <Icon className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
      <Input className="pl-8" {...props} />
    </div>
  );
}
