import { CustomInput } from "@/components/custom-input";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { DollarSign } from "lucide-react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

interface RemoteCreditDialogProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  sendRemoteCreditWsCallback: (content: number) => void
}

export const RemoteCreditDialog = ({ open, setOpen, sendRemoteCreditWsCallback }: RemoteCreditDialogProps) => {
  const [remoteCredit, setRemoteCredit] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const isProcessing = useRef(false);

  useEffect(() => {
    if (buttonDisabled) {
      setButtonDisabled(false);
    }
  }, [buttonDisabled]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(e.target.value);
    if (inputValue >= 0 && inputValue <= 100) {
      setRemoteCredit(inputValue);
    }
  };

  const handleAddCredit = () => {
    if (isProcessing.current) return; // Evita chamadas duplicadas
    isProcessing.current = true;

    setButtonDisabled(true);
    sendRemoteCreditWsCallback(remoteCredit);
    setOpen(false);

    // Reseta o estado de processamento após a operação
    setTimeout(() => {
      isProcessing.current = false;
    }, 1000); // Ajuste o tempo conforme necessário
  };


  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Adicionar Crédito Remoto</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col">
          <CustomInput
            Icon={DollarSign}
            type="number"
            max={200}
            min={1}
            onChange={handleChange}
          />
        </div>
        <DialogFooter className="sm:justify-end">
          <Button
            variant="destructive"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleAddCredit}
            disabled={buttonDisabled}
          >
            Adicionar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};