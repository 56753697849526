import { Subscription } from "@rails/actioncable";
import { MouseEvent, useRef } from "react";

function useCommandLogger(sub: Subscription | null) {
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const startLogging = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const command = event.currentTarget.dataset.command;

    if (intervalIdRef.current) return; // Evita múltiplos intervalos

    if (sub) {
      intervalIdRef.current = setInterval(() => {
        sub.perform("move", { command });
      }, 500);
    }
  };

  const stopLogging = () => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  return { startLogging, stopLogging };
}

export default useCommandLogger;
