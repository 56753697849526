import { router } from "@inertiajs/react";
import { RequestPayload, GlobalEventCallback, Method } from "@inertiajs/core";

interface BaseProps {
  path: string;
  only?: string[];
  onBefore?: GlobalEventCallback<"before">;
  onSuccess?: GlobalEventCallback<"success">;
  onError?: GlobalEventCallback<"error">;
  replace?: boolean
}

interface GetProps extends BaseProps {
  query?: RequestPayload;
}

interface DeleteProps extends BaseProps {}

interface VisitProps extends BaseProps {
  method?: Method;
}

interface PostProps extends BaseProps {
  payload?: RequestPayload;
}

interface PutProps extends BaseProps {
  payload?: RequestPayload;
}

export class Http {
  // Make a GET request
  static get({ path, query, only, onBefore, onSuccess, onError }: GetProps) {
    router.get(path, query, {
      only,
      onBefore,
      onSuccess,
      onError,
    });
  }

  static delete({ path, only, onBefore, onSuccess, onError }: DeleteProps) {
    router.delete(path, {
      only,
      onBefore,
      onSuccess,
      onError,
    });
  }

  static post({ path, payload, onBefore, onSuccess, onError }: PostProps) {
    router.post(path, payload, {
      onBefore,
      onSuccess,
      onError,
    })
  }

  static put({ path, payload, onBefore, onSuccess, onError }: PutProps) {
    router.put(path, payload, {
      onBefore,
      onSuccess,
      onError,
    })
  }

  // Redirect to page in inertia context
  static inertiaVisit({
    path,
    method = "get",
    only,
    replace,
    onBefore,
    onSuccess,
    onError,
  }: VisitProps) {
    router.visit(path, {
      method,
      only,
      onBefore,
      onSuccess,
      onError,
      replace,
      preserveState: true
    });
  }
}
