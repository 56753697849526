import { usePage } from "@inertiajs/react";
import { MachineLog } from "../machine_logs";
import { GlobalParamsInertia } from "@/types/global-params-inertia";
import { MachineLogsContent } from "../machine_logs/_components/machine-logs-content";
import { Button } from "@/components/ui/button";
import { ChevronLeft } from "lucide-react";
import { Http } from "@/lib/http";

interface MachinesLogsProps extends GlobalParamsInertia {
  machine_logs: MachineLog
}

export default function MachinesLogs() {
  const { machine_logs } = usePage().props as unknown as MachinesLogsProps
  const url = usePage().url

  function navitageBack() {
    const path = url.replace("/logs", "")

    Http.get({
      path
    })
  }

  return (
    <>
      <div>
        <Button variant="outline" onClick={navitageBack}>
          <ChevronLeft />
          Voltar
        </Button>
      </div>
      <h1 className="text-2xl">{machine_logs.name}</h1>
      <p>Registro de Logs</p>
      <MachineLogsContent logs={machine_logs.logs } />
    </>
  );
}