import { AppSidebar } from "@/components/app-sidebar";
import { ApplicationProvider } from "@/components/providers/application-provider";
import {
  SidebarInset,
  SidebarTrigger,
} from "@/components/ui/sidebar";
import { Toaster } from "@/components/ui/sonner";
import useFlashToast from "@/hooks/use-flash-toast";
import { Head } from "@inertiajs/react";
import { Separator } from "@radix-ui/react-separator";

const LayoutPrivate = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  useFlashToast();
  const host = location.host

  const actionCableUrl = `wss://${host}/cable`

  return (
    <ApplicationProvider>
      <Head>
        {process.env.NODE_ENV === "production" && (
          <meta name="action-cable-url" content={actionCableUrl} />
        )}
      </Head>
      <AppSidebar />
      <SidebarInset>
        <header className="flex h-16 shrink-0 items-center gap-2">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            {/* <Breadcrumb className="hidden">
              <BreadcrumbList>
                {breadcrumbsItems.map((item, index) => (
                  <BreadcrumbItem key={index}>
                    <Link href={item.href} className="transition-colors hover:text-foreground">
                      {item.label}
                    </Link>
                    {index < breadcrumbsItems.length - 1 && (
                      <BreadcrumbSeparator className="hidden md:block" />
                    )}
                  </BreadcrumbItem>
                ))}
              </BreadcrumbList>
            </Breadcrumb> */}
          </div>
        </header>
        <div className="flex flex-1 flex-col gap-4 p-4 pt-0">{children}</div>
      </SidebarInset>
      <Toaster />
    </ApplicationProvider>
  );
};

export default LayoutPrivate;
