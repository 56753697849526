import { Consumer, Subscription } from "@rails/actioncable";

interface SendMessageToMachineChannel {
  subscription: Subscription<Consumer> | null;
  content: number;
  method_name: "remote_credit" | "esp_configuration" | "move" | "receive";
  callback: () => void;
}

export const sendMessageToChannel = ({
  content,
  method_name,
  callback,
  subscription,
}: SendMessageToMachineChannel) => {
  subscription?.perform(method_name, { amount: content });

  callback();
};
