import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Client } from "@/types/user";
import { User } from "lucide-react";

interface ClientCardProps {
  client: Client;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function ClientCard({ client, onClick }: ClientCardProps) {
  return (
    <Card className="cursor-pointer select-none dark:border-primary" onClick={onClick}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-xl font-medium">
          <div className="text-primary">{client.name}</div>
          <p className="text-xs text-muted-foreground">{client.email}</p>
        </CardTitle>
        <Avatar className="h-12 w-12">
          {client.avatar ? (
            <AvatarImage src={client.avatar} alt={client.name} />
          ) : (
            <AvatarFallback>
              <User className="h-8 w-8" />
            </AvatarFallback>
          )}
        </Avatar>
      </CardHeader>
      <CardContent className="flex flex-row items-center justify-between space-y-0 pb-2">
        <div>
          <p className="text-xl font-bold">{client.total_machines}</p>
          <p className="text-xs text-muted-foreground">
            {client.total_machines === 1 ? "Máquina" : "Máquinas"}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <p className="text-xl font-bold">{client.total_online_machines}</p>
            <p className="text-xs text-muted-foreground">
              Online
            </p>
          </div>
          <div>
            <p className="text-xl font-bold">{client.total_offline_machines}</p>
            <p className="text-xs text-muted-foreground">
              Offline
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
