import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { cn, formatCurrency } from "@/lib/utils";
import { GlobalParamsInertia } from "@/types/global-params-inertia";
import { usePage } from "@inertiajs/react";
import { Boxes, Coins, LucideIcon, Monitor } from "lucide-react";
import { Area, AreaChart, Bar, BarChart, CartesianGrid, XAxis } from "recharts";

interface CardStatusProps {
  title: string;
  amount: number;
  isStock?: boolean;
  classColor?: "emerald" | "orange" | "default";
  Icon: LucideIcon;
}

const CardStatus = ({
  title,
  amount,
  isStock = false,
  classColor = "default",
  Icon,
}: CardStatusProps) => {
  const amountFormatted = isStock ? amount : formatCurrency(amount);

  return (
    <Card
      className={cn(
        classColor === "emerald" && "bg-emerald-500/20 border-emerald-500/50",
        classColor === "orange" && "bg-orange-500/20 border-orange-500/50",
        classColor === "default" && "bg-primary/10 border-primary/50"
      )}
    >
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-xl font-medium">
          <div
            className={cn(
              classColor === "orange" && "text-orange-500",
              classColor === "default" && "text-primary",
              classColor === "emerald" && "text-emerald-500"
            )}
          >
            {title}
          </div>
        </CardTitle>
        <Icon
          className={cn(
            "h-8 w-8",
            classColor === "emerald" && "text-emerald-500",
            classColor === "orange" && "text-orange-500",
            classColor === "default" && "text-primary"
          )}
        />
      </CardHeader>
      <CardContent>
        <h1 className="text-3xl">{amountFormatted}</h1>
      </CardContent>
    </Card>
  );
};

interface DashboardsIndexProps extends GlobalParamsInertia {
  dashboard: {
    total_amount: number;
    total_stock: number;
    total_machine: number;
    seven_days_history: {
      day: string;
      amount: number;
      stock: number;
    }[];
    three_months_history: {
      mount: string;
      amount: number;
      stock: number;
    }[];
  };
}

const chartConfig = {
  amount: {
    label: "Vendas",
    color: "#2563EB",
  },
  stock: {
    label: "Saídas",
    color: "#10B981",
  },
} satisfies ChartConfig;

export default function DashboardsIndex() {
  const {
    auth: { user },
    dashboard,
  } = usePage().props as unknown as DashboardsIndexProps;

  if (user.role === "admin") {
    return (
      <>
        <div className="grid auto-rows-min gap-4 md:grid-cols-3">
          <CardStatus
            title="Vendas"
            amount={dashboard.total_amount}
            Icon={Coins}
          />
          <CardStatus
            title="Saídas"
            isStock
            amount={dashboard.total_stock}
            Icon={Boxes}
            classColor="emerald"
          />
          <CardStatus
            title="Máquinas"
            isStock
            amount={dashboard.total_machine}
            Icon={Monitor}
            classColor="orange"
          />
        </div>
        <div className="grid auto-rows-min gap-4 lg:grid-cols-2">
          <Card>
            <CardHeader>
              <CardTitle>Últimos 7 dias</CardTitle>
              <CardDescription>Vendas e Saídas</CardDescription>
            </CardHeader>
            <CardContent>
              <ChartContainer config={chartConfig}>
                <AreaChart
                  accessibilityLayer
                  data={dashboard.seven_days_history}
                  margin={{
                    left: 16,
                    right: 0,
                  }}
                >
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey="day"
                    tickLine={true}
                    axisLine={false}
                    tickMargin={8}
                  />
                  <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent />}
                  />
                  <Area
                    dataKey="amount"
                    type="natural"
                    stroke="var(--color-amount)"
                    strokeWidth={2}
                    dot={false}
                    fill="var(--color-amount)"
                    fillOpacity={0.4}
                    stackId="a"
                  />
                  <Area
                    dataKey="stock"
                    type="natural"
                    stroke="var(--color-stock)"
                    strokeWidth={2}
                    dot={false}
                    fill="var(--color-stock)"
                    fillOpacity={0.4}
                    stackId="b"
                  />
                </AreaChart>
              </ChartContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Últimos 3 meses</CardTitle>
              <CardDescription>Vendas e Saídas</CardDescription>
            </CardHeader>
            <CardContent>
              <ChartContainer config={chartConfig}>
                <BarChart
                  accessibilityLayer
                  data={dashboard.three_months_history}
                  margin={{
                    left: 25,
                    right: 0,
                  }}
                >
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey="month"
                    tickLine={true}
                    axisLine={false}
                    tickMargin={8}
                  />
                  <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent />}
                  />
                  <Bar dataKey="amount" fill="var(--color-amount)" radius={4} />
                  <Bar dataKey="stock" fill="var(--color-stock)" radius={4} />
                </BarChart>
              </ChartContainer>
            </CardContent>
          </Card>
        </div>
      </>
    );
  }

  if (user.role === "client") {
    return (
      <>
        <div className="grid auto-rows-min gap-4 md:grid-cols-3">
          <CardStatus
            title="Vendas"
            amount={dashboard.total_amount}
            Icon={Coins}
          />
          <CardStatus
            title="Saídas"
            isStock
            amount={dashboard.total_stock}
            Icon={Boxes}
            classColor="emerald"
          />
          <CardStatus
            title="Máquinas"
            isStock
            amount={dashboard.total_machine}
            Icon={Monitor}
            classColor="orange"
          />
        </div>
        <div className="grid auto-rows-min gap-4 lg:grid-cols-2">
          <Card>
            <CardHeader>
              <CardTitle>Últimos 7 dias</CardTitle>
              <CardDescription>Vendas e Saídas</CardDescription>
            </CardHeader>
            <CardContent>
              <ChartContainer config={chartConfig}>
                <AreaChart
                  accessibilityLayer
                  data={dashboard.seven_days_history}
                  margin={{
                    left: 16,
                    right: 0,
                  }}
                >
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey="day"
                    tickLine={true}
                    axisLine={false}
                    tickMargin={8}
                  />
                  <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent />}
                  />
                  <Area
                    dataKey="amount"
                    type="natural"
                    stroke="var(--color-amount)"
                    strokeWidth={2}
                    dot={false}
                    fill="var(--color-amount)"
                    fillOpacity={0.4}
                    stackId="a"
                  />
                  <Area
                    dataKey="stock"
                    type="natural"
                    stroke="var(--color-stock)"
                    strokeWidth={2}
                    dot={false}
                    fill="var(--color-stock)"
                    fillOpacity={0.4}
                    stackId="b"
                  />
                </AreaChart>
              </ChartContainer>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Últimos 3 meses</CardTitle>
              <CardDescription>Vendas e Saídas</CardDescription>
            </CardHeader>
            <CardContent>
              <ChartContainer config={chartConfig}>
                <BarChart
                  accessibilityLayer
                  data={dashboard.three_months_history}
                  margin={{
                    left: 25,
                    right: 0,
                  }}
                >
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey="month"
                    tickLine={true}
                    axisLine={false}
                    tickMargin={8}
                  />
                  <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent />}
                  />
                  <Bar dataKey="amount" fill="var(--color-amount)" radius={4} />
                  <Bar dataKey="stock" fill="var(--color-stock)" radius={4} />
                </BarChart>
              </ChartContainer>
            </CardContent>
          </Card>
        </div>
      </>
    );
  }
}
