import {
  LayoutDashboard,
  Logs,
  LucideIcon,
  Monitor,
  Settings,
  Users,
} from "lucide-react";
import { dashboards } from "@/routes"

export type CanViewType = "client" | "admin" | "all" | "none";

export interface SidebarSecondaryNavProps {
  title: string;
  url: string;
  canView: CanViewType;
  icon: LucideIcon;
}

export interface SidebarMainNavProps {
  title: string;
  icon: LucideIcon;
  isActive?: boolean;
  items?: {
    title: string;
    url: string;
  }[];
}

export const sidebarSecondaryConstants: SidebarSecondaryNavProps[] = [
  {
    title: "Dashboard",
    url: dashboards(),
    icon: LayoutDashboard,
    canView: "all",
  },
  {
    title: "Clientes",
    url: "/admin/clients",
    icon: Users,
    canView: "admin",
  },
  {
    title: "Máquinas",
    url: "/machines",
    icon: Monitor,
    canView: "all",
  },
  {
    title: "Logs das Máquinas",
    url: "/machine_logs",
    icon: Logs,
    canView: "all",
  }
];

export const sidebarMainConstants = (url: string): SidebarMainNavProps[] => {
  const items = [
    {
      title: "Telemetrias",
      url: "/admin/telemetries",
    },
  ];

  const isActive = items.some((item) => item.url === url);

  const menus = [
    {
      title: "Configurações",
      icon: Settings,
      isActive,
      items
    },
  ];

  return menus
};
