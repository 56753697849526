import consumer from "@/lib/action-cable";

export default function MachineChannel({
  telemetriaId,
  storeId = "",
  emailClient = "",
  receivedCallback = (_data) => {},
  connectedCallback = () => {},
  disconnectedCallback = () => {},
}: {
  telemetriaId: string;
  storeId?: string;
  emailClient?: string;
  receivedCallback?: (data: any) => void;
  connectedCallback?: () => void;
  disconnectedCallback?: () => void;
}) {
  return consumer(telemetriaId).subscriptions.create(
    { channel: "MachineChannel", storeId, email: emailClient, is_web: true },
    {
      connected() {
        connectedCallback();
      },

      disconnected() {
        disconnectedCallback();
      },

      received(data) {
        receivedCallback(data);
      },
    }
  );
}
