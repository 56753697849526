import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Log } from "..";
import { Wifi, WifiOff } from "lucide-react";
import { formatLocalDateTime } from "@/lib/utils";

interface MachineLogsContentProps {
  logs: Log[];
}

export const MachineLogsContent = ({logs}: MachineLogsContentProps) => {
  const logsFormatted = logs.map((log) => {
    return {
      id: log.id,
      icon: log.status ? (
        <Wifi className="text-green-500" />
      ) : (
        <WifiOff className="text-red-500" />
      ),
      changed_at: formatLocalDateTime(log.changed_at),
      status: log.status ? "Online" : "Offline",
    };
  });

  return (
    <DataTable value={logsFormatted} paginator rows={10}>
      <Column field="icon" />
      <Column field="changed_at" header="Data" />
      <Column field="status" header="Status" />
    </DataTable>
  );
};
