import { CustomInput } from "@/components/custom-input";
import { ClientCard } from "./_components/client-card";
import { Search } from "lucide-react";
import { usePage } from "@inertiajs/react";
import { Client } from "@/types/user";
import { ChangeEvent, useState } from "react";
import { Http } from "@/lib/http";

interface ClientsIndexProps {
  clients: Client[];
}

function ClientsIndex() {
  const { clients } = usePage().props as unknown as ClientsIndexProps;

  //#region State
  const [_processingSearch, setProcessingSearch] = useState(false);

  //#endregion

  const SearchClients = (event: ChangeEvent<HTMLInputElement>) => {
    const q = event.target.value;

    Http.get({
      path: "/admin/clients",
      query: { q },
      only: ["clients", "pagy"],
      onBefore: () => setProcessingSearch(true),
      onSuccess: () => setProcessingSearch(false),
      onError: () => setProcessingSearch(false),
    });
  };

  return (
    <>
      <div className="flex w-full max-w-sm items-center space-x-2">
        <CustomInput Icon={Search} placeholder="Pesquisar" onChange={SearchClients} />
      </div>
      <div className="grid auto-rows-min gap-4 md:grid-cols-3">
        {clients.map((client) => (
          <ClientCard client={client} key={client.id} onClick={() => {
            Http.get({
              path: `/admin/clients/${client.id}/machines`,
            })
          }} />
        ))}
      </div>
    </>
  );
}

export default ClientsIndex;
