import "@/assets/remote_configuration.css";
import { useEffect, useState } from "react";
import machineChannel from "../../channels/machine-channel";
import { Subscription } from "@rails/actioncable";
import { Link, usePage } from "@inertiajs/react";
import { Machine } from "@/types/machine";
import { GlobalParamsInertia } from "@/types/global-params-inertia";
import { toast } from "sonner";
import useCommandLogger from "@/hooks/use-command-logger";
import { Button } from "@/components/ui/button";
import { ChevronLeft } from "lucide-react";

interface MachinesRemoteConfigurationProps extends GlobalParamsInertia {
  machine: Machine;
}

const UP = "5555",
  RIGHT = "6666",
  DOWN = "7777",
  LEFT = "8888",
  CENTER = "9999",
  CONFIGURATION = "1111";

export default function MachinesRemoteConfiguration() {
  const { machine } = usePage()
    .props as unknown as MachinesRemoteConfigurationProps;
  const url = usePage().url.substring(
    0,
    usePage().url.lastIndexOf("/remote_configuration")
  );

  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const { startLogging, stopLogging } = useCommandLogger(subscription);

  useEffect(() => {
    const subscription = machineChannel({
      telemetriaId: machine.telemetry.identifier,
      emailClient: machine.user.email,
      storeId: machine.mercado_pago_store_id,
      receivedCallback(data) {
        if (data?.error) {
          toast.error(data.error);
        }
      },
    });

    setSubscription(subscription);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const moveMachine = (content: string) => {
    subscription?.perform("move", { command: content });
  };

  return (
    <div className="mt-4">
      <Link href={url}>
        <Button variant="outline" className="mb-5">
          <ChevronLeft />
          Voltar
        </Button>
      </Link>
      <div className="flex justify-center">
        <button
          onClick={() => {
            moveMachine(CONFIGURATION);
          }}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
        >
          Iniciar Configuração
        </button>
      </div>

      <br />
      <br />
      <br />

      <div className="layout-controle flex-row">
        <div className="arrows-controle">
          <input
            type="hidden"
            name=""
            value="<%= @machine.telemetry.identifier %>"
          />

          <button
            className="arrow-button up"
            onClick={() => moveMachine(UP)}
            onMouseDown={startLogging}
            onMouseLeave={stopLogging}
            onMouseUp={stopLogging}
            data-command={UP}
          ></button>

          <button
            className="arrow-button left"
            onMouseDown={startLogging}
            onMouseLeave={stopLogging}
            onMouseUp={stopLogging}
            data-command={LEFT}
            onClick={() => moveMachine(LEFT)}
          ></button>
          <button
            id="button-confirm"
            data-action="machine#sendCommand"
            data-command={CENTER}
            onClick={() => moveMachine(CENTER)}
          ></button>
          <button
            className="arrow-button right"
            onMouseDown={startLogging}
            onMouseLeave={stopLogging}
            onMouseUp={stopLogging}
            data-command={RIGHT}
            onClick={() => moveMachine(RIGHT)}
          ></button>

          <button
            className="arrow-button down"
            data-command={DOWN}
            onMouseDown={startLogging}
            onMouseLeave={stopLogging}
            onMouseUp={stopLogging}
            onClick={() => moveMachine(DOWN)}
          ></button>
        </div>
      </div>
    </div>
  );
}
