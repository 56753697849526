import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";

interface CustomDialogProps {
  children: React.ReactNode;
  dialogTrigger: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
}

export function CustomDialog({
  children,
  dialogTrigger,
  title,
  description,
}: CustomDialogProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>{dialogTrigger}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
}
