import { GlobalParamsInertia } from "@/types/global-params-inertia";
import { usePage } from "@inertiajs/react";
import { useEffect } from "react";
import { toast } from "sonner";

function useFlashToast(): void {
  const { flash } = usePage().props as unknown as GlobalParamsInertia;

  useEffect(() => {
    if (flash.success) {
      if (typeof flash.success === "string") {
        // Caso seja uma string única
        toast.success(flash.success);
      } else {
        // Caso seja um array de mensagens
        flash.success.forEach((message: string) => {
          toast.success(message);
        });
      }
    }

    // Verificar se existe 'flash.alert' e tratá-lo como string ou array
    if (flash.alert) {
      if (typeof flash.alert === "string") {
        // Caso seja uma string única
        toast.error(flash.alert);
      } else {
        // Caso seja um array de mensagens
        flash.alert.forEach((message: string) => {
          toast.error(message);
        });
      }
    }
  }, [flash, toast]);
}

export default useFlashToast;
