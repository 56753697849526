import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "./ui/button";

interface ButtonConfirmationProps {
  buttonTrigger: React.ReactNode;
  dialogTitle: React.ReactNode;
  dialogDescription?: React.ReactNode;
  dialogContent: React.ReactNode;
  buttonTitleConfirmation: string;
  buttonTitleCancel: string;
  accept: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  reject?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export function ButtonConfirmation({
  buttonTrigger,
  dialogTitle,
  dialogDescription,
  dialogContent,
  buttonTitleConfirmation,
  buttonTitleCancel,
  accept,
  reject,
}: ButtonConfirmationProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>{buttonTrigger}</DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogDescription>{dialogDescription}</DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2">{dialogContent}</div>
        <DialogFooter className="sm:justify-end">
          <DialogClose asChild onClick={reject}>
            <Button variant="destructive">{buttonTitleCancel}</Button>
          </DialogClose>
          <DialogClose asChild onClick={accept}>
            <Button>{buttonTitleConfirmation}</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
