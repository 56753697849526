export interface CycleProps {
  title: string;
  amount: {
    total: string;
    cash: string;
    cards: string;
    refund: string;
    remote_credit: string;
    bank_transfer: string;
    outputs: number;
    refused: string;
  };
}

export const Cycles = ({ title, amount }: CycleProps) => {
  return (
    <div>
      <h1 className="text-2xl text-primary">{title}</h1>

      <div>
        <p>Total: {amount.total}</p>
        <p>Dinheiro: {amount.cash}</p>
        <p>PIX: {amount.bank_transfer}</p>
        <p>Débito/Crédito: {amount.cards}</p>
        <p>Saídas: {amount.outputs}</p>
        <p>Remoto: {amount.remote_credit}</p>
        <p>Estorno: {amount.refund}</p>
        <p>Recusados: {amount.refused}</p>
      </div>
    </div>
  );
};
