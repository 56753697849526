import { Link, useForm } from "@inertiajs/react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

type RegistrationFormProps = {
  loginPath: string;
  confirmationPath: string;
  registerPath: string;
};

type ErrorsFound = {
  email?: string[];
  password?: string[];
  password_confirmation?: string[];
  token_mercado_pago?: string[];
};

export function RegistrationForm({
  loginPath,
  registerPath,
  confirmationPath,
}: RegistrationFormProps) {
  const { data, setData, post, errors } = useForm({
    user: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      token_mercado_pago: "",
    },
  });

  const errorsFound = errors.user as unknown as ErrorsFound;

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    post(registerPath);
  }

  return (
    <Card className="mx-auto max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">Cadastro</CardTitle>
        <CardDescription>
          Insira seus dados para realizar seu cadastro.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form className="grid gap-4" onSubmit={handleSubmit}>
          <div className="grid gap-2">
            <Label
              htmlFor="email"
            >
              Nome
            </Label>
            <Input
              id="name"
              type="text"
              required
              value={data.user.name}
              onChange={(e) =>
                setData({ user: { ...data.user, name: e.target.value } })
              }
            />
          </div>
          <div className="grid gap-2">
            <Label
              htmlFor="email"
              className={errorsFound?.email ? "text-red-500" : ""}
            >
              Email
            </Label>
            <Input
              id="email"
              type="email"
              placeholder="m@example.com"
              required
              value={data.user.email}
              onChange={(e) =>
                setData({ user: { ...data.user, email: e.target.value } })
              }
              className={errorsFound?.email ? "border-red-500" : ""}
            />
            {errorsFound?.email && (
              <span className="text-red-500 text-xs">
                {errorsFound.email[0]}
              </span>
            )}
          </div>
          <div className="grid gap-2">
            <Label htmlFor="email">Mercado Pago Access Token</Label>
            <Input
              id="token_mercado_pago"
              type="text"
              placeholder="APP_USR-*****"
              required
              value={data.user.token_mercado_pago}
              onChange={(e) =>
                setData({
                  user: { ...data.user, token_mercado_pago: e.target.value },
                })
              }
            />
          </div>
          <div className="grid gap-2">
            <Label
              htmlFor="password"
              className={errorsFound?.password ? "text-red-500" : ""}
            >
              <span>Senha </span>
              <i>(6 caracteres no mínimo)</i>
            </Label>
            <Input
              id="password"
              type="password"
              required
              value={data.user.password}
              onChange={(e) =>
                setData({ user: { ...data.user, password: e.target.value } })
              }
            />
            {errorsFound?.password && (
              <span className="text-red-500 text-xs">
                {errorsFound.password[0]}
              </span>
            )}
          </div>
          <div className="grid gap-2">
            <Label
              htmlFor="password"
              className={
                errorsFound?.password_confirmation ? "text-red-500" : ""
              }
            >
              Confirmar Senha
            </Label>
            <Input
              id="password_confirmation"
              type="password"
              required
              value={data.user.password_confirmation}
              onChange={(e) =>
                setData({
                  user: { ...data.user, password_confirmation: e.target.value },
                })
              }
            />
            {errorsFound?.password_confirmation && (
              <span className="text-red-500 text-xs">
                {errorsFound.password_confirmation[0]}
              </span>
            )}
          </div>
          <Button type="submit" className="w-full">
            Cadastrar
          </Button>
        </form>
        <div className="mt-4 text-center text-sm">
          Já possui uma conta?{" "}
          <Link href={loginPath} className="underline cursor-pointer">
            Entre
          </Link>
        </div>
        <div className="mt-4 text-center text-sm">
          Não recebeu instruções de confirmação?{" "}
          <a href={confirmationPath} className="underline cursor-pointer">
            Reenviar confirmação
          </a>
        </div>
      </CardContent>
    </Card>
  );
}
