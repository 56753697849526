import * as React from "react";

import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { Link, usePage } from "@inertiajs/react";
import { GlobalParamsInertia } from "@/types/global-params-inertia";
import {
  CanViewType,
  SidebarSecondaryNavProps,
} from "@/constants/siderbar-constants";

interface NavSecondaryProps
  extends React.ComponentPropsWithoutRef<typeof SidebarGroup> {
  items: SidebarSecondaryNavProps[];
}

export function NavSecondary({ items, ...props }: NavSecondaryProps) {
  const {
    auth: { user },
  } = usePage().props as unknown as GlobalParamsInertia;
  const { setOpenMobile } = useSidebar()

  const canViewClassName = (canView: CanViewType): "" | "hidden" => {
    if (canView === "none") {
      return "hidden";
    }

    if (canView === "admin") {
      return user.role === "admin" ? "" : "hidden";
    }
    if (canView === "client") {
      return user.role === "client" ? "" : "hidden";
    }

    return "";
  };

  return (
    <SidebarGroup {...props}>
      <SidebarGroupContent>
        <SidebarMenu>
          {items.map((item) => (
            <SidebarMenuItem
              key={item.title}
              className={canViewClassName(item.canView)}
            >
              <SidebarMenuButton asChild size="default" onClick={() => setOpenMobile(false)}>
                <Link href={item.url} role="navigation">
                  <item.icon />
                  <span>{item.title}</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          ))}
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
}
