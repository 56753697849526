import { Link, usePage } from "@inertiajs/react";
import { MachineAnalysticsCard, MachineCard } from "./_components/machine-card";
import { Machine } from "@/types/machine";
import { Http } from "@/lib/http";
import { GlobalParamsInertia } from "@/types/global-params-inertia";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { User } from "@/types/user";
import { Button } from "@/components/ui/button";
import { ChevronLeft } from "lucide-react";

interface MachineByUser {
  user: User;
  machines: Machine[] | MachineAnalysticsCard[];
}

interface MachinesShowProps extends GlobalParamsInertia {
  machines?: Machine[] | MachineAnalysticsCard[];
  machines_by_user?: MachineByUser[];
  client_id: string;
}

export default function MachinesIndex() {
  const { auth, machines, machines_by_user } = usePage()
    .props as unknown as MachinesShowProps;
  const url = usePage().url;

  const accessPayments = (machine: Machine) => {
    if (auth.user.role === "admin") {
      Http.get({
        path: `/admin/clients/${machine.user.id}/machines/${machine.id}`,
      });
    }

    if (auth.user.role === "client") {
      Http.get({
        path: `/machines/${machine.id}`,
      });
    }
  };

  if (
    auth.user.role === "client" ||
    (url.startsWith("/admin/clients") && url.endsWith("machines"))
  ) {
    return (
      <>
        <div className={auth.user.role === "admin" ? "" : "hidden"}>
          <Link href={`/admin/clients/`}>
            <Button variant="outline" className="mb-5">
              <ChevronLeft />
              Voltar
            </Button>
          </Link>
        </div>
        <div className="grid auto-rows-min gap-4 md:grid-cols-2">
          {machines!.map((machine) => (
            <MachineCard
              key={machine.name}
              machine={machine as MachineAnalysticsCard}
              onClick={() => accessPayments(machine as Machine)}
            />
          ))}
        </div>
      </>
    );
  }

  if (auth.user.role === "admin") {
    return (
      <>
        {machines_by_user!.map((user_machines) => {
          const user = user_machines.user.name;
          const machines = user_machines.machines;

          return (
            <Accordion type="single" collapsible key={user}>
              <AccordionItem value={user}>
                <AccordionTrigger className="text-xl font-medium antialiased">
                  {user}
                </AccordionTrigger>
                <AccordionContent>
                  <div className="grid auto-rows-min gap-4 md:grid-cols-2">
                    {machines.map((machine) => (
                      <MachineCard
                        key={machine.name}
                        machine={machine as unknown as MachineAnalysticsCard}
                        onClick={() => accessPayments(machine as Machine)}
                      />
                    ))}
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          );
        })}
      </>
    );
  }
}
