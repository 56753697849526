import { LoginForm } from "@/components/login-form";
import { GlobalParamsInertia } from "@/types/global-params-inertia";
import { usePage } from "@inertiajs/react";
import { new_user_session } from '@/routes';

interface UsersSessionsNewInertiaProps extends GlobalParamsInertia {
  login_path: string;
  reset_password_path: string;
  register_path: string;
}

function UsersSessionsNew() {
  const { reset_password_path, register_path } = usePage()
    .props as unknown as UsersSessionsNewInertiaProps;

  return (
    <div className="flex h-screen w-full items-center justify-center px-4">
      <LoginForm
        loginPath={new_user_session()}
        resetPasswordPath={reset_password_path}
        registerPath={register_path}
      />
    </div>
  );
}

export default UsersSessionsNew;
