import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { ReactNode } from "react";

interface MachineLogsRootProps {
  id: string;
  name: string;
  children: ReactNode
}

export const MachineLogsAcoordion = ({id, name, children}: MachineLogsRootProps) => {
  return (
    <Accordion type="single" collapsible key={id}>
      <AccordionItem value={name}>
        <AccordionTrigger className="text-xl font-medium antialiased">
          {name}
        </AccordionTrigger>
        <AccordionContent>
          {children}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
