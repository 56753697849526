import { Command } from "lucide-react";
import { NavMain } from "@/components/nav-main";
import { NavUser } from "@/components/nav-user";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { usePage } from "@inertiajs/react";
import { NavSecondary } from "./nav-secondary";
import { GlobalParamsInertia } from "@/types/global-params-inertia";
import {
  sidebarMainConstants,
  sidebarSecondaryConstants,
} from "@/constants/siderbar-constants";
import { ModeThemeToggle } from "./mode-theme-toggle";

interface AppSidebarProps {}

export function AppSidebar({}: AppSidebarProps) {
  const {
    auth: { user },
  } = usePage().props as unknown as GlobalParamsInertia;
  const url = usePage().url;

  return (
    <Sidebar variant="inset">
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton
              size="lg"
              asChild
              variant="secondary"
              className="hover:bg-transparent"
            >
              <div>
                <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                  <Command className="size-4" />
                </div>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">BluePayPag</span>
                  <span className="truncate text-xs">
                    {user.role === "admin" ? "Administrador" : "Cliente"}
                  </span>
                </div>
                <ModeThemeToggle />
              </div>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <NavSecondary items={sidebarSecondaryConstants} />
        {user?.role === "admin" && (
          <NavMain items={sidebarMainConstants(url)} />
        )}
      </SidebarContent>
      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
    </Sidebar>
  );
}
