import { GlobalParamsInertia } from "@/types/global-params-inertia";
import { usePage } from "@inertiajs/react";
import LayoutDefault from "./default";
import LayoutPrivate from "./private";

const validateUrlToRenderLayoutDefault = (url: string): boolean => {
  return url === "/" || url === "/users/sign_up" || url === "/users/sign_in";
}

export function Layout({ children }: { children: React.ReactNode }) {
  const { auth } = usePage().props as unknown as GlobalParamsInertia;
  const url = usePage().url

  if (!auth || validateUrlToRenderLayoutDefault(url)) {
    return <LayoutDefault>{children}</LayoutDefault>;
  }

  if (auth.user.role === "admin" || auth.user.role === "client") {
    return <LayoutPrivate>{children}</LayoutPrivate>;
  }
}
