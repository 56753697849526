import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { GlobalParamsInertia } from "@/types/global-params-inertia";
import { usePage } from "@inertiajs/react";
import { Clipboard, Check } from "lucide-react";
import { useState } from "react";

export default function UserRegistrionsEdit() {
  const {
    auth: { url_ipn, user },
  } = usePage().props as unknown as GlobalParamsInertia;
  const [copyText, setCopyText] = useState(false);

  return (
    <>
      {user.role === "client" && (
        <div className="w-[500px]">
          <Label>Link Mercado Pago</Label>
          <div className="flex rounded-lg shadow-sm shadow-black/5">
            <Input
              id="input-20"
              className="-me-px flex-1 rounded-e-none shadow-none focus-visible:z-10"
              value={url_ipn}
              type="text"
              disabled
            />
            <button
              className="inline-flex w-9 items-center justify-center rounded-e-lg border border-input bg-background text-sm text-muted-foreground/80 ring-offset-background transition-shadow hover:bg-accent hover:text-foreground focus:z-10 focus-visible:border-ring focus-visible:text-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring/30 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
              aria-label="Subscribe"
              onClick={() => {
                setCopyText(true);

                setTimeout(() => {
                  console.log("Copiado");
                  setCopyText(false);
                }, 2000);
              }}
            >
              {!copyText ? (
                <Clipboard
                  size={16}
                  strokeWidth={2}
                  aria-hidden="true"
                  onClick={() => {
                    navigator.clipboard.writeText(url_ipn);
                  }}
                />
              ) : (
                <Check size={16} strokeWidth={2} aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
