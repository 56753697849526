import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Link } from "@inertiajs/react";
import { AlertTriangle, Home } from "lucide-react";

function Error({ status }: { status: number }) {
  const title =
    {
      503: "Service Unavailable",
      500: "Server Error",
      404: "Page Not Found",
      403: "Forbidden",
      401: "Não autorizado",
    }[status] || "Unexpected error";

  const description = {
    503: "Sorry, we are doing some maintenance. Please check back soon.",
    500: "Whoops, something went wrong on our servers.",
    404: "Sorry, the page you are looking for could not be found.",
    403: "Sorry, you are forbidden from accessing this page.",
    401: "Desculpe, você não está autorizado a acessar esta página.",
  }[status];

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-900">
      <Card className="w-full max-w-md">
        <CardHeader>
          <div className="flex items-center justify-center mb-4">
            <AlertTriangle className="h-12 w-12 text-yellow-500" />
          </div>
          <CardTitle className="text-2xl font-bold text-center">
            Oops! Algo deu errado
          </CardTitle>
          <CardDescription className="text-center">
            Pedimos desculpas pelo inconveniente. Ocorreu um erro.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <p className="text-sm text-gray-500 dark:text-gray-400 text-center mb-4">
            {title}
          </p>

          <p className="text-xs text-gray-400 dark:text-gray-500 text-center">
            {description}
          </p>
        </CardContent>
        <CardFooter className="flex justify-center space-x-4">
          <Button asChild>
            <Link href="/">
              <Home className="mr-2 h-4 w-4" />
              Ir para página inicial
            </Link>
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default Error;
