import {ButtonConfirmation} from "@/components/button-confirmation";
import {CustomInput} from "@/components/custom-input";
import {Button} from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {Http} from "@/lib/http";
import {GlobalParamsInertia} from "@/types/global-params-inertia";
import {Machine} from "@/types/machine";
import {Payment} from "@/types/payment";
import {usePage} from "@inertiajs/react";
import {
  Airplay,
  Banknote,
  CalendarDays,
  ChevronDown,
  ChevronLeft,
  Coins,
  CreditCard,
  DollarSign,
  PackageMinus,
  Power,
  PowerOff,
  Settings,
  SquareX,
  TicketX,
  Trash2,
} from "lucide-react";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {FaPix} from "react-icons/fa6";
import {Subscription} from "@rails/actioncable";
import machineChannel from "../../channels/machine-channel";
import {sendMessageToChannel} from "./_components/channels/sendMessageToChannel";
import {formatCurrency} from "@/lib/utils";
import {RemoteCreditDialog} from "./_components/remote-credit-dialog";
import {CycleProps, Cycles} from "./_components/cycles";
import {toast} from "sonner";
import {SiMercadopago} from "react-icons/si"
import {BRONZE, plansValidate} from "@/lib/plans-validate";

interface MachineShowProps extends GlobalParamsInertia {
  machine: Machine;
  payments: Payment[];
}

function typePaymentFormat(type: string) {
  switch (type) {
    case "cash":
      return "Dinheiro";
    case "pix":
      return "Pix";
    case "debit_card":
      return "Débito";
    case "credit_card":
      return "Crédito";
    case "refund":
      return "Estorno";
    case "remote_credit":
      return "Remoto";
    case "bank_transfer":
      return "PIX";
    case "stock":
      return "Estoque";
    case "refused":
      return "Recusado";
    case "account_money":
      return "MercadoPago"
  }
}

function typePaymentIcon(type: string) {
  switch (type) {
    case "cash":
      return <Banknote/>;
    case "debit_card":
      return <CreditCard/>;
    case "credit_card":
      return <CreditCard/>;
    case "refund":
      return <TicketX/>;
    case "remote_credit":
      return <Airplay/>;
    case "bank_transfer":
      return <FaPix/>;
    case "stock":
      return <PackageMinus/>;
    case "refused":
      return <SquareX/>;
    case "account_money":
      return <SiMercadopago size={24}/>;
  }
}

export default function MachineShow() {
  const {machine, payments, auth} = usePage()
    .props as unknown as MachineShowProps;
  const currentUrl = usePage().url;
  const planActions = machine.telemetry.plan?.actions;
  const planType = machine.telemetry.plan?.plan_type;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOnOffOpen, setIsDialogOnOffOpen] = useState(false);
  const [isDialogGenericConfigOpen, setIsDialogGenericConfigOpen] =
    useState(false);
  const [isDialogDestroyMachineOpen, setIsDialogDestroyMachineOpen] =
    useState(false);
  const [subscription, setSubscription] = useState<Subscription | null>(null);

  useEffect(() => {
    const subscription = machineChannel({
      telemetriaId: machine.telemetry.identifier,
      emailClient: machine.user.email,
      storeId: machine.mercado_pago_store_id,
      receivedCallback(data) {
        if (data?.error) {
          toast.error(data.error);
        }
      },
    });

    setSubscription(subscription);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const sendRemoteCreditWs = (content: number) => {
    sendMessageToChannel({
      subscription,
      content,
      method_name: "remote_credit",
      callback: () => {
        Http.inertiaVisit({
          path: currentUrl,
          only: ["machine", "payments"],
          replace: true,
        });
      },
    });
  };

  const sendGenericConfigWs = async (content: number) => {
    sendMessageToChannel({
      subscription,
      content,
      method_name: "esp_configuration",
      callback: () => {
        Http.inertiaVisit({
          path: currentUrl,
          only: ["machine", "payments"],
          replace: true,
        });
      },
    });
  };

  const GenericConfigDialog = () => {
    const [remoteCredit, setRemoteCredit] = useState(0);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = Number(e.target.value);
      setRemoteCredit(inputValue);
    };

    return (
      <Dialog
        open={isDialogGenericConfigOpen}
        onOpenChange={setIsDialogGenericConfigOpen}
      >
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Enviar Payload para o ESP</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col">
            <CustomInput
              Icon={DollarSign}
              type="number"
              max={200}
              min={1}
              onChange={handleChange}
            />
          </div>
          <DialogFooter className="sm:justify-end">
            <Button
              variant="destructive"
              onClick={() => setIsDialogGenericConfigOpen(false)}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                sendGenericConfigWs(remoteCredit);
                setIsDialogGenericConfigOpen(false);
              }}
            >
              Adicionar
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  const OnOffMachineDialog = () => {
    return (
      <Dialog open={isDialogOnOffOpen} onOpenChange={setIsDialogOnOffOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>
              Desligar/Ligar Máquina {machine.telemetry.identifier}
            </DialogTitle>
          </DialogHeader>
          <DialogFooter className="sm:justify-end">
            <Button
              variant="destructive"
              onClick={() => setIsDialogOnOffOpen(false)}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                Http.post({
                  path: `/admin/clients/${machine.user.id}/machines/${machine.id}/on_off_machine`,
                });
                setIsDialogOnOffOpen(false);
              }}
            >
              {machine.force_offline ? "Ligar" : "Desligar"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  const DestroyMachineDialog = () => {
    return (
      <Dialog open={isDialogDestroyMachineOpen} onOpenChange={setIsDialogDestroyMachineOpen}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>
              Excluir definitivamente a Máquina {machine.telemetry.identifier}
            </DialogTitle>
          </DialogHeader>
          <DialogFooter className="sm:justify-end">
            <Button
              variant="destructive"
              onClick={() => setIsDialogDestroyMachineOpen(false)}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                Http.delete({
                  path: `/admin/clients/${machine.user.id}/machines/${machine.id}/`,
                });
                setIsDialogDestroyMachineOpen(false);
              }}
            >
              Excluir
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  const paymentsFormat = payments.map((payment) => {
    const payment_date = new Date(payment.payment_date).toLocaleString("pt-BR");

    return {
      ...payment,
      payment_date,
      type: typePaymentFormat(payment.type),
      icon: typePaymentIcon(payment.type),
      actions: (
        <div>
          <ButtonConfirmation
            buttonTrigger={
              <Button variant="outline" size="icon">
                <Trash2 className="h-2 w-2"/>
              </Button>
            }
            dialogTitle={`Remover Registro`}
            dialogContent={
              <div className="flex flex-col">
                <ul>
                  <li className="text-primary">
                    Data de Registro:{" "}
                    <span className="text-foreground">{payment_date}</span>
                  </li>
                  <li className="text-primary">
                    Tipo:{" "}
                    <span className="text-foreground">
                      {typePaymentFormat(payment.type)}
                    </span>
                  </li>
                  <li className="text-primary">
                    {payment.type === "stock"
                      ? "Quantidade registrada"
                      : "Valor"}
                    : <span className="text-foreground">{payment.amount}</span>
                  </li>
                </ul>
                <br/>
                <p>Tem certeza que deseja remover esse registro?</p>
              </div>
            }
            buttonTitleCancel="Cancelar"
            buttonTitleConfirmation="Remover"
            reject={() => {
            }}
            accept={() => {
              const urlDelete = `/admin/clients/${machine.user.id}/machines/${machine.id}/destroy_payment/${payment.id}`;
              Http.delete({
                path: urlDelete,
                only: ["machine", "payments"],
              });
            }}
          />
        </div>
      ),
    };
  });

  //#region Cycles
  const currentCycle: CycleProps = {
    title: "Ciclo Atual",
    amount: {
      total: formatCurrency(machine.current_cycle.total_amount),
      bank_transfer: formatCurrency(machine.current_cycle.bank_transfer),
      cards: formatCurrency(machine.current_cycle.cards),
      cash: formatCurrency(machine.current_cycle.cash),
      outputs: machine.current_cycle.stock_output,
      refund: formatCurrency(machine.current_cycle.refund),
      remote_credit: formatCurrency(machine.current_cycle.remote_credit),
      refused: formatCurrency(machine.current_cycle.refused),
    },
  };
  const totalCycle: CycleProps = {
    title: "Ciclo Total",
    amount: {
      total: formatCurrency(machine.total_cycle.total_amount),
      bank_transfer: formatCurrency(machine.total_cycle.bank_transfer),
      cards: formatCurrency(machine.total_cycle.cards),
      cash: formatCurrency(machine.total_cycle.cash),
      outputs: machine.total_cycle.stock_output,
      refund: formatCurrency(machine.total_cycle.refund),
      remote_credit: formatCurrency(machine.total_cycle.remote_credit),
      refused: formatCurrency(machine.total_cycle.refused),
    },
  };
  const dailyCycle: CycleProps = {
    title: "Ciclo Diário",
    amount: {
      total: formatCurrency(machine.daily_cycle.total_amount),
      bank_transfer: formatCurrency(machine.daily_cycle.bank_transfer),
      cards: formatCurrency(machine.daily_cycle.cards),
      cash: formatCurrency(machine.daily_cycle.cash),
      outputs: machine.daily_cycle.stock_output,
      refund: formatCurrency(machine.daily_cycle.refund),
      remote_credit: formatCurrency(machine.daily_cycle.remote_credit),
      refused: formatCurrency(machine.daily_cycle.refused),
    },
  };
  //#endregion

  const cleanPayments = () => {
    if (auth.user.role === "admin") {
      Http.post({
        path: `/admin/clients/${machine.user.id}/machines/${machine.id}/clean_payments`,
        only: ["machine", "payments"],
      });
    }

    if (auth.user.role === "client") {
      Http.post({
        path: `/machines/${machine.id}/clean_payments`,
        only: ["machine", "payments"],
      });
    }
  };


  const navigateToMachineEdit = () => {
    const path = currentUrl.concat("/edit");
    Http.inertiaVisit({
      path,
    });
  };

  const navigateToMachineLogs = () => {
    const path = currentUrl.concat("/logs");
    Http.inertiaVisit({
      path,
    });
  };

  const destroyAllPayments = () => {
    Http.delete({
      path: `/admin/clients/${machine.user.id}/machines/${machine.id}/destroy_all_payments`,
      replace: true,
    });
  };

  return (
    <>
      <div>
        <Button
          variant="outline"
          className="mb-5"
          onClick={() => {
            if (auth.user.role === "admin") {
              Http.inertiaVisit({
                path: `/admin/clients/${machine.user.id}/machines`,
              });
            }

            if (auth.user.role === "client") {
              Http.inertiaVisit({
                path: `/machines`,
              });
            }
          }}
        >
          <ChevronLeft/>
          Voltar
        </Button>
      </div>
      <RemoteCreditDialog
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        sendRemoteCreditWsCallback={sendRemoteCreditWs}
      />
      <GenericConfigDialog/>
      <OnOffMachineDialog/>
      <DestroyMachineDialog/>
      <div className="flex">
        <div>
          <h1 className="text-2xl font-medium text-primary">{machine.name}</h1>
          <p className="text-muted-foreground text-sm">{machine.description}</p>
        </div>

        <div className="ml-auto flex gap-2">
          {auth.user.role === "admin" && (
            <Button
              className="hidden"
              onClick={() => setIsDialogOnOffOpen(true)}
              variant={"destructive"}
            >
              {machine.force_offline ? (
                <>
                  <Power/>
                  Forçar Online
                </>
              ) : (
                <>
                  <PowerOff/>
                  Forçar Offline
                </>
              )}
            </Button>
          )}
          <Button onClick={navigateToMachineLogs}>
            <CalendarDays/>
            Histórico Online
          </Button>
          <Button onClick={navigateToMachineEdit}>Editar Máquina</Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button>
                Ações <ChevronDown/>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
              align="end"
            >
              <DropdownMenuItem className="p-2" onClick={cleanPayments}>
                <Trash2/>
                Ciclo Atual
              </DropdownMenuItem>
              {/*{machine.telemetry.plan?.plan_type === "platinum" && (*/}
              <DropdownMenuItem
                className="p-2"
                onClick={() => {
                  if (auth.user.role === "admin") {
                    Http.get({
                      path: `/admin/clients/${machine.user.id}/machines/${machine.id}/remote_configuration`,
                    });
                  }

                  if (auth.user.role === "client") {
                    Http.get({
                      path: `/machines/${machine.id}/remote_configuration`,
                    });
                  }
                }}
                disabled={auth.user.role === "client" && !machine.online}
              >
                <Settings/>
                Configuração Presencial
              </DropdownMenuItem>
              {/*)}*/}
              <DropdownMenuItem
                className="p-2"
                onClick={() => setIsDialogOpen(true)}
                disabled={!machine.online}
              >
                <Coins/>
                Crédito Remoto
              </DropdownMenuItem>

              {auth.user.role === "admin" && (
                <>
                  <DropdownMenuItem className="p-2 bg-red-500 hover:bg-red-600!" onClick={destroyAllPayments}>
                    <Trash2/>
                    Ciclo Total
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="p-2 bg-red-500 hover:bg-red-600"
                    onClick={() => setIsDialogGenericConfigOpen(true)}
                    disabled={!machine.online}
                  >
                    <Settings/>
                    Payload ADMIN
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    className="p-2 bg-red-500 hover:bg-red-600"
                    onClick={() => setIsDialogDestroyMachineOpen(true)}
                  >
                    <Settings/>
                    Deletar Máquina
                  </DropdownMenuItem>
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <div>
        <p className="text-primary hidden">
          Plano Atual:{" "}
          <span className="text-foreground">
            {machine.telemetry.plan?.name}
          </span>
        </p>
        <p className="text-primary">
          Versão: <span className="text-foreground">{machine.version}</span>
        </p>
        <p className="text-primary">
          Estoque: <span className="text-foreground">{machine.stock}</span>
        </p>
        <p className="text-primary">
          Status:{" "}
          <span className="text-foreground">
            {machine.online ? "Online" : "Offline"}
          </span>
        </p>
      </div>

      <div className="flex justify-between">
        <Cycles title={dailyCycle.title} amount={dailyCycle.amount}/>
        <Cycles title={currentCycle.title} amount={currentCycle.amount}/>
        <Cycles title={totalCycle.title} amount={totalCycle.amount}/>
      </div>

      {/*{auth.user.role === "admin" && (*/}
      <div>
        <DataTable
          loading={false}
          value={paymentsFormat}
          tableStyle={{fontSize: "0.875rem"}}
          size="small"
          globalFilterFields={["payment_date", "type", "amount"]}
          emptyMessage="Nenhum registro encontrado"
        >
          <Column field="icon" style={{width: "5%"}}></Column>
          <Column
            field="payment_date"
            header="Data de Registro"
            style={{width: "20%"}}
          ></Column>
          <Column field="type" header="Tipo"></Column>
          <Column field="amount" header="Valor"></Column>
          <Column field="actions" header="#"></Column>
        </DataTable>
      </div>
      {/*)}*/}

      {/*{auth.user.role === "client" && (*/}
      {/*  <div>*/}
      {/*    <DataTable*/}
      {/*      loading={false}*/}
      {/*      value={paymentsFormat}*/}
      {/*      tableStyle={{ fontSize: "0.875rem" }}*/}
      {/*      size="small"*/}
      {/*      globalFilterFields={["payment_date", "type", "amount"]}*/}
      {/*      emptyMessage="Nenhum registro encontrado"*/}
      {/*    >*/}
      {/*      <Column field="icon" style={{ width: "5%" }}></Column>*/}
      {/*      <Column*/}
      {/*        field="payment_date"*/}
      {/*        header="Data de Registro"*/}
      {/*        style={{ width: "20%" }}*/}
      {/*      ></Column>*/}
      {/*      <Column*/}
      {/*        field="type"*/}
      {/*        header="Tipo"*/}
      {/*        body={*/}
      {/*          plansValidate(planActions, planType) ? null : InvisibleColumn*/}
      {/*        }*/}
      {/*      ></Column>*/}
      {/*      <Column*/}
      {/*        field="amount"*/}
      {/*        header="Valor"*/}
      {/*        body={*/}
      {/*          plansValidate(planActions, planType) ? null : InvisibleColumn*/}
      {/*        }*/}
      {/*      ></Column>*/}
      {/*    </DataTable>*/}
      {/*  </div>*/}
      {/*)}*/}
    </>
  );
}

// payments = Payment.joins(machine: { telemetry: :plan }).where(type: "credit_card", machine: m).select("plans.name as plan_name, plans.plan_type as plan_type")