import {Badge} from "@/components/ui/badge";
import {Button} from "@/components/ui/button";
import {
  GlobalParamsInertia,
  Paginable,
  Pagy,
} from "@/types/global-params-inertia";
import {useForm, usePage} from "@inertiajs/react";
import {VisitOptions} from "@inertiajs/core";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {ChangeEvent, useState} from "react";
import {Input} from "@/components/ui/input";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  Search,
  Trash2,
} from "lucide-react";
import {Label} from "@radix-ui/react-dropdown-menu";
import {ButtonConfirmation} from "@/components/button-confirmation";
import {CustomDialog} from "@/components/custom-dialog";
import {DialogClose, DialogFooter} from "@/components/ui/dialog";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
} from "@/components/ui/pagination";
import {CustomInput} from "@/components/custom-input";
import {Http} from "@/lib/http";
import {Telemetry} from "@/types/telemetry";
import {Switch} from "@/components/ui/switch";
import machineChannel from "../../channels/machine-channel";
import {toast} from "sonner";
import {Dropdown} from "primereact/dropdown";
import {Plan} from "@/types/plan";

interface TelemetriesIndexProps extends GlobalParamsInertia, Paginable {
  telemetries: Telemetry[];
  plans: Plan[];
}

//#region Private Components
//#region Custom Pagination
const PaginatorCustom = ({
                           pagy,
                           changePage,
                         }: {
  pagy: Pagy;
  changePage: (page: number) => void;
}) => {
  const buttonDisabled =
    "text-accent-foreground/20 hover:bg-transparent hover:text-accent-foreground/20";

  return (
    <div>
      <Pagination>
        <PaginationContent>
          <PaginationItem>
            <PaginationLink
              onClick={() => pagy.page !== 1 && changePage(1)}
              className={pagy.page === 1 ? buttonDisabled : "cursor-pointer"}
            >
              <ChevronsLeft className="h-4 w-4"/>
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink
              onClick={() => pagy.page !== 1 && changePage(pagy.prev ?? 1)}
              className={pagy.page === 1 ? buttonDisabled : "cursor-pointer"}
            >
              <ChevronLeft className="h-4 w-4"/>
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink
              onClick={() => changePage(pagy.page)}
              className="hover:bg-transparent"
            >
              {pagy.page}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <span>de</span>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink
              onClick={() => changePage(pagy.last)}
              className="hover:bg-transparent"
            >
              {pagy.last}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink
              onClick={() =>
                pagy.last !== pagy.page && changePage(pagy.next ?? pagy.last)
              }
              className={
                pagy.last === pagy.page ? buttonDisabled : "cursor-pointer"
              }
            >
              <div className="flex">
                <ChevronRight className="h-4 w-4"/>
              </div>
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink
              onClick={() => pagy.page !== pagy.last && changePage(pagy.last)}
              className={
                pagy.last === pagy.page ? buttonDisabled : "cursor-pointer"
              }
            >
              <ChevronsRight className="h-4 w-4"/>
            </PaginationLink>
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </div>
  );
};
//#endregion

//#region Add Telemetry
const AddTelemetry = ({
                        data,
                        setData,
                        post,
                      }: {
  data: {
    telemetry: {
      identifier: string;
    };
  };
  setData: any;
  post: (url: string, options?: VisitOptions) => void;
}) => {
  return (
    <CustomDialog
      dialogTrigger={<Button>Nova Telemetria</Button>}
      title="Adicionar Telemetria"
      description="Adicionar uma nova telemetria"
    >
      <div className="flex flex-col items-start gap-2">
        <Label className="text-right">Identificador</Label>
        <Input
          value={data.telemetry.identifier}
          className="col-span-3"
          onChange={(e) =>
            setData({telemetry: {identifier: e.target.value}})
          }
        />
      </div>
      <DialogFooter>
        <DialogClose asChild>
          <Button
            type="button"
            variant="secondary"
            onClick={() => {
              setData({telemetry: {identifier: ""}});
            }}
          >
            Cancelar
          </Button>
        </DialogClose>
        <DialogClose asChild>
          <Button
            onClick={() => {
              post("/admin/telemetries");
            }}
          >
            Salvar
          </Button>
        </DialogClose>
      </DialogFooter>
    </CustomDialog>
  );
};
//#endregion
//#endregion

const TelemetriesIndex = () => {
  //#region Inertia Hooks
  const {telemetries, pagy, plans} = usePage()
    .props as unknown as TelemetriesIndexProps;
  const {delete: deleteTelemetry, processing: processingDelete} = useForm();
  const {data, setData, processing, post} = useForm({
    telemetry: {
      identifier: "",
    },
  });
  //#endregion

  //#region Actions
  const [processingSearch, setProcessingSearch] = useState(false);
  const [q, setQ] = useState("");

  const changePage = (page: number) => {
    Http.get({
      path: `/admin/telemetries`,
      query: {q, page},
      only: ["telemetries", "pagy"],
    });
  };

  const SearchTelemetries = (event: ChangeEvent<HTMLInputElement>) => {
    const q = event.target.value;
    setQ(q);

    Http.get({
      path: "/admin/telemetries",
      query: {q},
      only: ["telemetries", "pagy"],
      onBefore: () => setProcessingSearch(true),
      onSuccess: () => setProcessingSearch(false),
      onError: () => setProcessingSearch(false),
    });
  };
  //#endregion

  //#region Table values
  const values = telemetries.map((telemetry: Telemetry) => {
    return {
      ...telemetry,
      plan: (
        <Dropdown
          options={plans}
          value={telemetry?.plan}
          onChange={(e) => {
            Http.put({
              path: `/admin/telemetries/${telemetry.id}`,
              payload: {
                plan_id: e.value.id,
              }
            })
          }}
          optionLabel="name"
          className="h-10"
          checkmark
        />
      ),
      blockedAction: (
        <>
          <Switch
            checked={!telemetry.blocked}
            onCheckedChange={() => {
              const subscription = machineChannel({
                telemetriaId: telemetry.identifier,
                emailClient: telemetry.user.email,
                storeId: telemetry.machine?.mercado_pago_store_id,
                receivedCallback(data) {
                  if (data?.error) {
                    toast.error(data.error);
                  }
                },
              });

              Http.post({
                path: `/admin/telemetries/${telemetry.id}/on_off_telemetry`,
                payload: {
                  blocked: !telemetry.blocked,
                  q,
                  page: pagy.page,
                },
              });

              subscription.unsubscribe();
            }}
          />
        </>
      ),
      actions: (
        <>
          <ButtonConfirmation
            buttonTrigger={
              <Button variant="outline" size="icon">
                <Trash2 className="h-2 w-2"/>
              </Button>
            }
            dialogTitle={`Remover Telemetria`}
            dialogContent={
              <div>
                Tem certeza que deseja remover a telemetria{" "}
                <b>{telemetry.identifier}</b>?
                {telemetry.user.name && (
                  <>
                    <br/>
                    <br/>
                    <b>Usuário:</b> {telemetry.user.name}
                  </>
                )}
                {!telemetry.user.name && (
                  <>
                    <br/>
                    <br/>
                    <b>Nenhum usuário associado</b>
                  </>
                )}
              </div>
            }
            buttonTitleCancel="Cancelar"
            buttonTitleConfirmation="Remover"
            reject={() => {
            }}
            accept={() => {
              deleteTelemetry(`/admin/telemetries/${telemetry.id}`);
            }}
          />
        </>
      ),
    };
  });

  const statusBodyTemplate = (data: Telemetry) => {
    return (
      <Badge
        variant={data.active === "Ativa" ? "success" : "destructive"}
        className="capitalize"
      >
        {data.active}
      </Badge>
    );
  };
  //#endregion

  return (
    <div className="container mx-auto">
      <div className="flex justify-between">
        <div className="flex w-full max-w-sm items-center space-x-2">
          <CustomInput
            Icon={Search}
            placeholder="Pesquisar"
            onChange={(event) => SearchTelemetries(event)}
          />
        </div>
        <AddTelemetry data={data} setData={setData} post={post}/>
      </div>
      <div className="py-4">
        <DataTable
          loading={processing || processingDelete || processingSearch}
          value={values}
          tableStyle={{fontSize: "1rem"}}
          size="small"
          globalFilterFields={["identifier", "user.name", "active"]}
          emptyMessage="Nenhum registro encontrado"
          dataKey="id"
        >
          <Column field="blockedAction"/>
          <Column
            field="identifier"
            sortable
            header="Identificador"
            style={{width: "25%"}}
          ></Column>
          <Column field="plan" header="Plano" style={{width: "25%"}}></Column>
          <Column
            field="machine.name"
            sortable
            header="Máquina"
            style={{width: "25%"}}
          ></Column>
          <Column
            field="user.name"
            header="Usuário"
            style={{width: "25%"}}
          ></Column>
          <Column
            field="active"
            header="Status"
            style={{minWidth: "12rem"}}
            body={statusBodyTemplate}
          ></Column>
          <Column field="actions" style={{width: "25%"}}></Column>
        </DataTable>
        <br/>
        <PaginatorCustom pagy={pagy} changePage={changePage}/>
      </div>
    </div>
  );
};

export default TelemetriesIndex;
