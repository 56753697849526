import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { formatCurrency } from "@/lib/utils";
import { User } from "@/types/user";
import { Wifi, WifiOff } from "lucide-react";

interface MachineCardProps {
  machine: MachineAnalysticsCard;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export interface MachineAnalysticsCard {
  id: string;
  name: string;
  description: string;
  online: boolean;
  user: User;
  payments: CategoryPayment;
}

interface CategoryPayment {
  bank_transfer: PaymentCycle;
  cards: PaymentCycle;
  cash: PaymentCycle;
  stock: PaymentCycle;
  total: PaymentCycle;
}

interface PaymentCycle {
  today: number;
  last_seven_days: number;
  month: number;
}

export function MachineCard({ machine, onClick }: MachineCardProps) {
  const data = [
    {
      name: "Total",
      today: machine.payments.total.today,
      last_seven_days: machine.payments.total.last_seven_days,
      month: machine.payments.total.month,
    },
    {
      name: "Dinheiro",
      today: machine.payments.cash.today,
      last_seven_days: machine.payments.cash.last_seven_days,
      month: machine.payments.cash.month,
    },
    {
      name: "Pix",
      today: machine.payments.bank_transfer.today,
      last_seven_days: machine.payments.bank_transfer.last_seven_days,
      month: machine.payments.bank_transfer.month,
    },
    {
      name: "Débito/Crédito",
      today: machine.payments.cards.today,
      last_seven_days: machine.payments.cards.last_seven_days,
      month: machine.payments.cards.month,
    },
    {
      name: "Saídas",
      today: machine.payments.stock.today,
      last_seven_days: machine.payments.stock.last_seven_days,
      month: machine.payments.stock.month,
    },
  ];

  return (
    <Card
      className="cursor-pointer select-none dark:border-primary"
      onClick={onClick}
    >
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-xl font-medium">
          <div className="text-primary">{machine.name}</div>
          <p className="text-xs text-muted-foreground">{machine.description}</p>
        </CardTitle>
        <Avatar className="h-12 w-12">
          <AvatarFallback>
            {machine.online && <Wifi className="h-8 w-8 text-green-600" />}
            {!machine.online && <WifiOff className="h-8 w-8 text-red-500" />}
          </AvatarFallback>
        </Avatar>
      </CardHeader>
      <CardContent className="flex flex-row items-center justify-between space-y-0 pb-2">
        <Table className="overflow-x-scroll min-w-52">
          <TableCaption>Entradas no período</TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">#</TableHead>
              <TableHead className="w-[100px]">Hoje</TableHead>
              <TableHead className="w-[100px]"> 7 dias</TableHead>
              <TableHead className="w-[100px]">Mês</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((value, index) => (
              <TableRow key={index}>
                <TableCell className="font-medium">{value.name}</TableCell>
                <TableCell>{value.name === "Saídas" ? value.today : formatCurrency(value.today) ?? "-"}</TableCell>
                <TableCell>{value.name === "Saídas" ? value.last_seven_days : formatCurrency(value.last_seven_days) ?? "-"}</TableCell>
                <TableCell className="">{value.name === "Saídas" ? value.month : formatCurrency(value.month) ?? "-"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
