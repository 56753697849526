import { GlobalParamsInertia } from "@/types/global-params-inertia";
import { usePage } from "@inertiajs/react";
import { MachineLogsAcoordion } from "./_components/machine-logs-accordion";
import { MachineLogsContent } from "./_components/machine-logs-content";

interface MachineLogsIndexProps extends GlobalParamsInertia {
  machine_logs: UserMachineLog[];
}

export interface UserMachineLog {
  id: string;
  name: string;
  machines?: MachineLog[];
  logs?: Log[];
}

export interface MachineLog {
  id: string;
  name: string;
  logs: Log[];
}

export interface Log {
  id: string;
  machine_id: string;
  status: string;
  changed_at: string;
  description: string;
}

export default function MachineLogsIndex() {
  const {
    machine_logs,
    auth: { user },
  } = usePage().props as unknown as MachineLogsIndexProps;

  if (user.role === "admin") {
    return (
      <>
        {machine_logs.map((user, idx) => {
          return (
            <MachineLogsAcoordion key={`user-${idx}`} id={user.id} name={user.name}>
              {user.machines!.map((machine, idx) => {
                return (
                  <div className="px-4" key={`machine-${machine.id}-${idx}`} >
                    <MachineLogsAcoordion id={machine.id} name={machine.name}>
                      <MachineLogsContent logs={machine.logs} />
                    </MachineLogsAcoordion>
                  </div>
                );
              })}
            </MachineLogsAcoordion>
          );
        })}
      </>
    );
  }

  if (user.role === "client") {
    return (
      <>
        {machine_logs.map((user, idx) => {
          return (
            <MachineLogsAcoordion key={`user-${idx}`} id={user.id} name={user.name}>
              <div className="px-4">
                <MachineLogsContent logs={user.logs!} />
              </div>
            </MachineLogsAcoordion>
          );
        })}
      </>
    )
  }
}
