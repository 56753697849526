import { Toaster } from "@/components/ui/sonner";
import useFlashToast from "@/hooks/use-flash-toast";

function LayoutDefault({ children }: { children: React.ReactNode }) {
  useFlashToast();

  return (
    <>
      <main className="w-full">{children}</main>
      <Toaster />
    </>
  );
}

export default LayoutDefault;
